<template>
  <div class="desc-wrapper policy-wrapper">
    <vue-custom-scrollbar class="scroll-area" v-if="Object.keys(policies).length != 0">
      <div class="policy-section" v-for="(ele,index) in policies" :key="index">
        <h2 class="policy-category">{{ index }}</h2>

        <div class="policy-sub-section" v-for="(sub, i) in ele" :key="`${index}-${i}`">
          <h3 class="policy-sub-category">{{ sub.subcategory }}</h3>
          <p class="policy-detail">{{ sub.policy }}</p>
        </div>
      </div>
      <a href="#" class="see-more">महत्वपूर्ण नीतिहरु सम्बन्धित अधिक जानकारीका लागि यहाँ जानुहोस् 
        <unicon name="sign-out-alt"></unicon>
      </a>
    </vue-custom-scrollbar>
    <NoDataFound v-else />
  </div>
</template>

<script>
import { groupBy } from "@/helper/helper";
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  components: {
    vueCustomScrollbar
  },
  computed: {
    policies() {
      let fiscalYear = this.$store.state.fiscal_year;

      let policies = this.$store.state.policies.filter((ele) => {
        if (ele.fiscalyear === fiscalYear) return ele;
      });

      return policies ? groupBy("category")(policies) : {};
    }
  }
};
</script>

<style lang="scss" scoped>
  .policy-section {
    padding-right: 80px;
    @media all and (max-width: 3199px) {
      padding-right: 40px;
    }
    & + .policy-section {
      margin-top: 162px;
       @media all and (max-width: 3199px) {
         margin-top: 0;
       }
    }
  }
  .policy-sub-section {
    margin-bottom: 80px;
    @media all and (max-width: 3199px) {
         margin-bottom: 32px;
    }
  }
</style>
